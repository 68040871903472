import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["content", "expandedIcon", "collapsedIcon"];

  connect() {
    // Apply stored state from sessionStorage (for this specific toggle) on connect
    this.applyStoredState();

    // Register event listener for Turbo frame load, scoped to this controller
    document.addEventListener("turbo:frame-load", this.restoreState.bind(this));
  }

  disconnect() {
    // Clean up the event listener when the controller is disconnected
    document.removeEventListener(
      "turbo:frame-load",
      this.restoreState.bind(this),
    );
  }

  toggle() {
    // Toggle the visibility of the content and store the state
    if (this.contentTarget.classList.contains("hidden")) {
      this.showContent();
      sessionStorage.setItem(this.storageKey(), "expanded");
    } else {
      this.hideContent();
      sessionStorage.setItem(this.storageKey(), "collapsed");
    }
  }

  showContent() {
    this.contentTarget.classList.remove("hidden");
    this.expandedIconTarget.classList.remove("hidden");
    this.collapsedIconTarget.classList.add("hidden");
  }

  hideContent() {
    this.contentTarget.classList.add("hidden");
    this.expandedIconTarget.classList.add("hidden");
    this.collapsedIconTarget.classList.remove("hidden");
  }

  restoreState(event) {
    // This method is triggered on Turbo frame reload
    const frame = event.target;

    // Check if the Turbo frame contains the controller element
    if (frame.contains(this.element)) {
      // Only restore state for this specific toggle
      this.applyStoredState();
    }
  }

  applyStoredState() {
    // Retrieve the saved state from sessionStorage (expanded or collapsed)
    const savedState = sessionStorage.getItem(this.storageKey());
    if (savedState === "expanded") {
      this.showContent();
    } else if (savedState === "collapsed") {
      this.hideContent();
    } else {
      // Default behavior: hide content if no state found
      this.hideContent();
    }
  }

  storageKey() {
    // Create a unique session storage key based on the element's ID or a custom identifier
    return `toggle-state-${this.element.id || this.element.dataset.identifier || this.index}`;
  }

  get index() {
    // Helper method to determine the index of this controller instance among siblings
    return Array.from(this.element.parentElement.children).indexOf(
      this.element,
    );
  }
}
